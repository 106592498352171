import { NAME_PARAM_CONTENT_LANGUAGE } from "@/constants/common";
import { wait } from "@/utils/wait";

const ENABLED_POOL_PROMISES = false;

interface QueryablePromise {
  isFulfilled: () => boolean;
  isPending: () => boolean;
  isRejected: () => boolean;
}

function makeqQueryablePromise(promise: any) {
  if (typeof promise.then !== 'function') {
    console.error('Error makeqQueryablePromise', typeof promise, promise);
    return promise;
  }

  if (promise.isFulfilled) return promise;

  // Set initial state
  let isPending = true;
  let isRejected = false;
  let isFulfilled = false;

  const result = promise.then(
    function (v: any) {
      isFulfilled = true;
      isPending = false;
      return v;
    },
    function (e: any) {
      isRejected = true;
      isPending = false;
      throw e;
    }
  );

  result.isFulfilled = function () {
    return isFulfilled;
  };
  result.isPending = function () {
    return isPending;
  };
  result.isRejected = function () {
    return isRejected;
  };

  return result as QueryablePromise;
}

interface requestArgs {
  tokenStorage: any;
  provider: any;
  url: string;
  requestData?: any;
  options?: { [key: string]: any };
  isSensitiveContentLanguage?: boolean;
  withPagination?: boolean;
}

interface requestWrapper extends requestArgs {
  method: "get" | "post" | "patch" | "put" | "delete";
}

const requestWrapper = async ({
  tokenStorage,
  provider,
  url,
  method,
  requestData,
  options = {},
  isSensitiveContentLanguage,
}: requestWrapper) => {
  await wait();

  let preOptions = { ...options };

  if (method !== "get") {
    if (isSensitiveContentLanguage) {
      const URLParams = new URLSearchParams(window?.location?.search);
      const value = URLParams.get(NAME_PARAM_CONTENT_LANGUAGE);
      if (value) {
        if (typeof preOptions.headers === "object") {
          if (value) {
            preOptions.headers["Accept-Language"] = value;
          }
        } else {
          if (value) {
            preOptions.headers = { "Accept-Language": value };
          }
        }
      }
    }
  }

  let promise;

  promise = provider.instance({
    url,
    method,
    data: requestData,
    ...preOptions,
  });

  return promise
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      console.log('CRUD fetcher error', {...error}, typeof provider, provider);

      const status = error?.response?.status;

      if (status === provider.code.HTTP_401_UNAUTHORIZED) {
        // TODO
        tokenStorage?.erase();
      }

      if (status === provider.code.HTTP_404_NOT_FOUND || status === provider.code.HTTP_500_INTERNAL_SERVER_ERROR) {
        const isUncaughtBackendError = error?.response?.data?.[0] === '<';
        if (isUncaughtBackendError) {
          throw { message: error.message, http_status: status };
        }
      }

      throw { ...(error?.response?.data || {}), http_status: status };
    });
};

const processResponse = async (response: any, params: requestArgs) => {
  let responseData = response.data;

  if(params.withPagination) {
    const count = response.headers["x-pagination-count"];
    const limit = response.headers["x-pagination-limit"];

    responseData = {
      result: response.data,
      count: parseInt(`${count}`),
      limit: parseInt(`${limit}`),
      nextPage: response.headers["x-pagination-next"],
      prevPage: response.headers["x-pagination-prev"]
    }
  }

  return responseData;
};

type poolPromises = { [key: string]: QueryablePromise };
const poolPromises: poolPromises = {};

/* Methods */
const get = async (params: requestArgs) => {
  const { url } = params;

  const key = url;

  if (
    ENABLED_POOL_PROMISES &&
    poolPromises[key] &&
    poolPromises[key].isPending()
  ) {
    return poolPromises[key];
  }

  const promiseResponse = makeqQueryablePromise(
    requestWrapper({ ...params, method: "get" })
  );

  poolPromises[key] = promiseResponse;

  return promiseResponse.then((response: any) =>
    processResponse(response, params)
  );
};

const post = async (params: requestArgs) => {
  const promiseResponse = requestWrapper({ ...params, method: "post" });
  return promiseResponse.then((response) => processResponse(response, params));
};

const put = async (params: requestArgs) => {
  const promiseResponse = requestWrapper({ ...params, method: "put" });
  return promiseResponse.then((response) => processResponse(response, params));
};

const patch = async (params: requestArgs) => {
  const promiseResponse = requestWrapper({ ...params, method: "patch" });
  return promiseResponse.then((response) => processResponse(response, params));
};

const del = async (params: requestArgs) => {
  const promiseResponse = requestWrapper({ ...params, method: "delete" });
  return promiseResponse.then((response) => processResponse(response, params));
};

class crudFetcher {
  provider: any;
  tokenStorage?: any;

  constructor(provider: any, tokenStorage?: any) {
    this.provider = provider;
    this.tokenStorage = tokenStorage;
  }

  get = (args: any) => get({ provider: this.provider, ...args });
  post = (args: any) => post({ provider: this.provider, ...args });
  put = (args: any) => put({ provider: this.provider, ...args });
  patch = (args: any) => patch({ provider: this.provider, ...args });
  delete = (args: any) => del({ provider: this.provider, ...args });
}

export default crudFetcher;
