const DOMAINE = `${process.env.NEXT_PUBLIC_DOMAINE_STAGE}`;
const DOMAINE_SELLER = `${process.env.NEXT_PUBLIC_SELLER_DOMAINE_STAGE}`;
const DOMAINE_ADMIN = `${process.env.NEXT_PUBLIC_ADMIN_DOMAINE_STAGE}`;
const GTM_ID = `${process.env.NEXT_PUBLIC_GTM_ID}`;

export const APP_API = process.env.NODE_ENV === "production" ? `/api/v1` : `${process.env.NEXT_PUBLIC_APP_API}`;
export const APP_SELLER_API =
  process.env.NODE_ENV === "production" ? `/seller-api/v1` : `${process.env.NEXT_PUBLIC_APP_SELLER_API}`;
export const APP_NEXT_API = `/api`;

// Authentication type
type typeToken = "bearer" | "CSRF";
export const typeToken: typeToken = "bearer";

export const NAME_PARAM_CONTENT_LANGUAGE = "content-language";

export const DEFAULT_PRODUCT_PICTURE = `${DOMAINE}/assets/default-product.png`;
export const QUERY_SEARCH_PARAM_NAME = "q";
export const IS_SSR = typeof window === "undefined";
export const DEV_LANGUAGE = `${process.env.NEXT_PUBLIC_APP_DEV_LANGUAGE}`.toLowerCase() === "true";
export const MESSAGE_REDIRECT_PRODUCT_NOT_FOUND = 'redirect-message-not-found';
export const DEFAULT_PAGE_SIZE = 64;

export const DevLanguage = {
  code: "en",
  name: "English",
  is_active: true,
  is_main: true,
  ordering: 0,
  value: "en",
};

type APP_ENV = {
  DOMAINE: string;
  DOMAINE_SELLER: string;
  DOMAINE_ADMIN: string;
  APP_NEXT_API: string;
  APP_API: string;
  APP_SELLER_API: string;
  IS_PRODUCTION: boolean;
  GTM_ID: string;
  DEV_LANGUAGE: boolean;
};
declare global {
  var __env: APP_ENV | undefined;
}

global.__env = {
  DOMAINE,
  DOMAINE_SELLER,
  DOMAINE_ADMIN,
  APP_API,
  APP_NEXT_API,
  APP_SELLER_API,
  IS_PRODUCTION: process.env.NODE_ENV === "production",
  GTM_ID,
  DEV_LANGUAGE,
};

Object.freeze(global.__env);
